import { FC, useState } from 'react'
import { styled } from '@liftfoils/styles'
import { SectionFeaturesSliderProps } from '@liftfoils/models'
import { Gradient, Media } from '@liftfoils/components'

const Wrap = styled('div', {
  position: 'relative',
  display: 'grid',
  $posterHeight: true,
})

const Slides = styled('div', {
  gridRow: 1,
  gridColumn: 1,
  display: 'grid',
})

const Slide = styled('div', {
  gridRow: 1,
  gridColumn: 1,
  variants: {
    isVisible: {
      true: {
        opacity: 1,
        transition: 'opacity 200ms',
      },
      false: {
        opacity: 0,
        transition: 'opacity 300ms',
      },
    },
  },
})

const Overlay = styled('div', {
  gridRow: 1,
  gridColumn: 1,
  zIndex: 1,
  display: 'grid',
  gridTemplateRows: '1fr min-content 1fr',
  py: '$8',
  gridGap: '$8',
  px: '$containerMargin',
})

const Heading = styled('h2', {
  color: 'white',
  textAlign: 'center',
  variants: {
    isVisible: {
      true: {
        display: 'block',
      },
      false: {
        display: 'none',
      },
    },
  },
})

const CenterWrap = styled('div', {
  gridRow: 2,
  lift_font: 'caps02',
  minHeight: '3em', // let's save the space for 3 lines of text
  display: 'grid',
  alignItems: 'center',
  alignSelf: 'end',
})

const BottomWrap = styled('div', {
  gridRow: 3,
  alignSelf: 'start',
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  alignContent: 'center',
  justifyItems: 'center',
  color: 'white',
})

const MobileDescriptionTab = styled('div', {
  variants: {
    isVisible: {
      true: {
        display: 'block',
        '@md': {
          display: 'none',
        },
      },
      false: {
        display: 'none',
      },
    },
  },
})

const MobileDescription = styled('div', {
  lift_font: 'body02',
  color: '$white_08',
  gridColumnStart: 1,
  gridColumnEnd: 4,
  $mt: 'M',
  textAlign: 'center',
  '@lg': {
    display: 'none',
  },
})

const Tab = styled('button', {
  position: 'relative',
  display: 'block',
  cursor: 'pointer',
  textAlign: 'center',
  maxWidth: '338px',
  background: 'none',
  pt: '$9',
  '&::before': {
    content: `''`,
    display: 'block',
    position: 'absolute',
    top: '0',
    left: '50%',
    transform: 'translateX(-50%)',
    width: '$7',
    height: '$7',
    borderRadius: '$rMax',
    border: '1px solid',
    borderColor: '$white_06',
  },
  variants: {
    isActive: {
      true: {
        '&::before': {
          backgroundColor: 'teal',
          borderColor: 'teal',
        },
      },
    },
  },
})

const TabTitle = styled('h3', {
  lift_font: 'body05',
  color: 'white',
  '& + p': {
    $mt: 'S',
  },
})

const TabDescription = styled('div', {
  lift_font: 'body02',
  color: '$white_06',
  display: 'none',
  '@md': {
    display: 'block',
  },
  variants: {
    isActive: {
      true: {
        color: '$white_08',
      },
    },
  },
})

export const SectionFeaturesSlider: FC<SectionFeaturesSliderProps> = ({
  features,
  priority,
}) => {
  const [highlightedItemIndex, setHighlightedItemIndex] = useState(1)

  if (!features || features.length < 2) {
    return null
  }

  return (
    <Wrap>
      <Slides>
        {features.map((slide, index) => {
          return (
            <Slide key={index} isVisible={highlightedItemIndex === index}>
              {slide.background && (
                <Media
                  {...slide.background}
                  layout="fill"
                  priority={priority && index === 0}
                  sizes="100vw"
                  hardcropRatio={{
                    '856': 'square',
                    '1440': 'landscape2',
                    '1920': 'landscape2',
                    '2880': 'landscape1',
                  }}
                  controlsPosition={'bottom'}
                  alt={slide.background?.alt ?? ''}
                />
              )}
            </Slide>
          )
        })}
        <Gradient from={'bottom'} css={{ height: '50%' }} />
      </Slides>
      <Overlay>
        <CenterWrap>
          {features.map((slide, index) => {
            return (
              <Heading
                key={`${slide.title}${index}`}
                isVisible={highlightedItemIndex === index}
              >
                {slide.title}
              </Heading>
            )
          })}
        </CenterWrap>

        <BottomWrap>
          {features.map((slide, index) => {
            return (
              <Tab
                key={`${slide.shortTitle}${index}`}
                isActive={highlightedItemIndex === index}
                onMouseEnter={() => setHighlightedItemIndex(index)}
              >
                <TabTitle>{slide.shortTitle}</TabTitle>
                <TabDescription isActive={highlightedItemIndex === index}>
                  {slide.description}
                </TabDescription>
              </Tab>
            )
          })}
          <MobileDescription>
            {features.map((slide, index) => {
              return (
                <MobileDescriptionTab
                  key={index}
                  isVisible={highlightedItemIndex === index}
                >
                  {slide.description}
                </MobileDescriptionTab>
              )
            })}
          </MobileDescription>
        </BottomWrap>
      </Overlay>
    </Wrap>
  )
}
